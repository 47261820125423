<template>
    <div>
        <div class="portf-form-bench-row portf-form-bench-title_row">
            <div class="portf-form-bench-col-del"></div>
            <div class="portf-form-bench-col">Index</div>
            <div class="portf-form-bench-col">Percent</div>
        </div>
        <div class="portf-form-bench-row">
            <div class="portf-form-bench-col-del">
                <el-button type="text" class="form-delete-btn" @click="remove_row">
                    <i class="el-icon-circle-close"></i>
                </el-button>
            </div>
            <div class="portf-form-bench-col">
                <el-select v-model="row_data.ind_id">
                    <el-option
                        v-for="item in options"
                        :key="item.ticker"
                        :label="item.name"
                        :value="item.ticker">
                    </el-option>
                </el-select>
            </div>
            <div class="portf-form-bench-col">
                <el-input-number
                    v-model="row_data.ind_percent"
                    :controls="false"
                    :step="0.1"
                    :max="1"
                    :min="0.1">
                    </el-input-number>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
    },

    data(){
        return {
            row_data: this.value,
        }
    },

    methods: {
        remove_row(){
            this.$emit('remove-value')
        }
    },
}
</script>
